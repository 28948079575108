exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informationssicherheit-js": () => import("./../../../src/pages/informationssicherheit.js" /* webpackChunkName: "component---src-pages-informationssicherheit-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-private-sicherheit-js": () => import("./../../../src/pages/private-sicherheit.js" /* webpackChunkName: "component---src-pages-private-sicherheit-js" */),
  "component---src-pages-schulungen-js": () => import("./../../../src/pages/schulungen.js" /* webpackChunkName: "component---src-pages-schulungen-js" */),
  "component---src-pages-social-engineering-js": () => import("./../../../src/pages/social-engineering.js" /* webpackChunkName: "component---src-pages-social-engineering-js" */),
  "component---src-pages-trainingsmodul-basis-js": () => import("./../../../src/pages/trainingsmodul-basis.js" /* webpackChunkName: "component---src-pages-trainingsmodul-basis-js" */),
  "component---src-pages-trainingsmodul-spezial-js": () => import("./../../../src/pages/trainingsmodul-spezial.js" /* webpackChunkName: "component---src-pages-trainingsmodul-spezial-js" */),
  "component---src-pages-unsere-erfahrung-js": () => import("./../../../src/pages/unsere-erfahrung.js" /* webpackChunkName: "component---src-pages-unsere-erfahrung-js" */),
  "component---src-pages-unternehmenssicherheit-js": () => import("./../../../src/pages/unternehmenssicherheit.js" /* webpackChunkName: "component---src-pages-unternehmenssicherheit-js" */)
}

